import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [];

export const dictionary = {
		"/policy": [12],
		"/[lang=lang]": [~2],
		"/[lang=lang]/[search=search]": [~11],
		"/[lang=lang]/[near=near]": [9],
		"/[lang=lang]/[contact=contact]": [4],
		"/[lang=lang]/[about=about]": [3],
		"/[lang=lang]/[near=near]/[placeType]": [10],
		"/[lang=lang]/[country]": [~5],
		"/[lang=lang]/[country]/[city]": [~6],
		"/[lang=lang]/[country]/[city]/[placeSlug]": [~7],
		"/[lang=lang]/[country]/[city]/[placeSlug]/[place]": [~8]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';